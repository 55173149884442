import React, { FC } from 'react';
import './video-feature.css';

interface VideoProps {
    videoLink: string;
}

const VideoFeature: FC<VideoProps> = (props) => {
  return (
    <div className="fullvideo-container">
      <iframe
        className="fullvideo"
        src={props.videoLink}
        frameBorder="0"
        allowFullScreen
      >
      </iframe>
    </div>
  );
};

export default VideoFeature;
