import React, { useEffect } from 'react';
import SEO from '../../../components/seo';
import VideoFeature from '../../../components/sections/video-feature';
import { gaButtonEvent } from '../../../services/visitor-info';
import './../../../components/buttons/cta-buttons.css';

const VapeTestimonial = () => {
  return (
    <div>
      <SEO
        title="Patronscan Testimonial"
        description="Patronscan prevents youth from accessing vape by catching fakes. Scan IDs at storefronts
        and on websites."
      />
      <div className="mt-32 mb-10">
        <VideoFeature videoLink="https://player.vimeo.com/video/460810941" />
      </div>
      <div className="flex flex-col content-center text-center justify-center mb-10">
        <div className="text-base mx-5">
          <h2>
            Patronscan prevents youth from accessing vape by catching fakes. Scan IDs at storefronts
            and on websites.
          </h2>
        </div>
        <div className="justify-center">
          <a
            href="/retail-vapor"
            className="testimonial-cta"
            onClick={() => {
              gaButtonEvent('LearnMore', 'VapeTestimonialVideo');
            }}
          >
            Learn More
          </a>
        </div>
      </div>
    </div>
  );
};

export default VapeTestimonial;
